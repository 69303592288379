import React from "react"
import { injectIntl, Link } from 'gatsby-plugin-intl';
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import * as errorImage from "../images/common/404-dog.png";

const NotFoundPage = (props) => (
    <Layout staticHeader>
        <SEO title="Página no encontrada" />
        <section className="error-page">
            <img src={errorImage} alt="Dog"/>
            <div className="section container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="error-message">
                            <h2>
                                {props.intl.formatMessage({id: "Oops!"}) }
                            </h2>
                            <p>
                                {props.intl.formatMessage({id: "Sorry! We can't seem to find the page you are looking for."})}
                                {props.intl.formatMessage({id: "You can go to our"})} <Link to="/">{props.intl.formatMessage({id: "Homepage"})}</Link> {props.intl.formatMessage({id: "or contact us through our"})} <Link to="/contact">{props.intl.formatMessage({id: "Contact page"})}</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)

export default injectIntl(NotFoundPage)
